import { XhrResponse } from './XhrResponse';

export type XhrBody =
  | Blob
  | BufferSource
  | Document
  | FormData
  | null
  | string
  | URLSearchParams;

export type XhrSubscription =
  | 'abort'
  | 'error'
  | 'load'
  | 'loadend'
  | 'loadstart'
  | 'progress'
  | 'readystatechange'
  | 'timeout';

export type XhrRequestInit = {
  method: string;
  body?: XhrBody;
  headers?: Record<string, string>;
};

export type XhrEventCallback = (e: ProgressEvent) => void;

export class XhrRequest extends XMLHttpRequest {
  #eventListeners: Record<string, EventListenerOrEventListenerObject> = {};

  #subscriptions: Record<string, XhrEventCallback[]> = {
    abort: [],
    error: [],
    load: [],
    loadend: [],
    loadstart: [],
    progress: [],
    readystatechange: [],
    timeout: [],
  };

  private addEventListeners() {
    Object.keys(this.#subscriptions)
      .filter((key) => {
        return Object.prototype.hasOwnProperty.call(this.#subscriptions, key);
      })
      .forEach((key) => {
        this.#eventListeners[key] = (e: Event) => {
          this.#subscriptions[key].forEach((subscriber) => {
            subscriber(e as ProgressEvent);
          });
        };

        this.addEventListener(key, this.#eventListeners[key]);
      });
  }

  private removeEventListeners() {
    Object.keys(this.#eventListeners)
      .filter((key) => {
        return Object.prototype.hasOwnProperty.call(this.#eventListeners, key);
      })
      .forEach((key) => {
        this.removeEventListener(key, this.#eventListeners[key]);
      });
  }

  delete(
    url: string | URL,
    body: XhrBody = null,
    headers: Record<string, string> = {}
  ): Promise<XhrResponse> {
    return this.request(url, {
      method: 'DELETE',
      headers,
      body,
    });
  }

  get(
    url: string | URL,
    headers?: Record<string, string>
  ): Promise<XhrResponse> {
    return this.request(url, {
      method: 'GET',
      headers,
    });
  }

  patch(
    url: string | URL,
    body: XhrBody = null,
    headers: Record<string, string> = {}
  ): Promise<XhrResponse> {
    return this.request(url, {
      method: 'PATCH',
      headers,
      body,
    });
  }

  post(
    url: string | URL,
    body: XhrBody = null,
    headers: Record<string, string> = {}
  ): Promise<XhrResponse> {
    return this.request(url, {
      method: 'POST',
      headers,
      body,
    });
  }

  put(
    url: string | URL,
    body: XhrBody = null,
    headers: Record<string, string> = {}
  ): Promise<XhrResponse> {
    return this.request(url, {
      method: 'PUT',
      headers,
      body,
    });
  }

  request(url: string | URL, init: XhrRequestInit): Promise<XhrResponse> {
    this.open(init.method, url);

    if (init.headers) {
      const { headers } = init;

      Object.keys(headers)
        .filter((key) => Object.prototype.hasOwnProperty.call(headers, key))
        .forEach((key) => {
          this.setRequestHeader(key, headers[key]);
        });
    }

    return this.send(init.body);
  }

  send(body?: XhrBody): Promise<XhrResponse> {
    return new Promise((resolve, reject) => {
      const onSuccess = () => {
        if (this.status > 199 && this.status < 300) {
          resolve(new XhrResponse(this));
        } else {
          reject(new Error(`${this.status} ${this.statusText}`));
        }
      };

      const onError = () => {
        reject(new Error(`${this.status} ${this.statusText}`));
      };

      const onAbort = () => {
        reject(new Error('Transaction aborted'));
      };

      const onComplete = () => {
        this.removeEventListeners();
        this.unsubscribe('load', onSuccess);
        this.unsubscribe('error', onError);
        this.unsubscribe('abort', onAbort);
        this.unsubscribe('loadend', onComplete);
      };

      this.subscribe('load', onSuccess);
      this.subscribe('error', onError);
      this.subscribe('abort', onAbort);
      this.subscribe('loadend', onComplete);

      this.addEventListeners();

      super.send(body);
    });
  }

  subscribe(event: XhrSubscription, cb: XhrEventCallback): void {
    if (Object.prototype.hasOwnProperty.call(this.#subscriptions, event)) {
      this.#subscriptions[event].push(cb);
    }
  }

  unsubscribe(event: XhrSubscription, cb: XhrEventCallback): void {
    if (Object.prototype.hasOwnProperty.call(this.#subscriptions, event)) {
      const pos = this.#subscriptions[event].indexOf(cb);

      if (pos !== -1) {
        this.#subscriptions[event] = this.#subscriptions[event].filter(
          (fn) => fn !== cb
        );
      }
    }
  }
}

export default { XhrRequest };
