import { LatLng, LatLngBounds } from 'leaflet';
import AmbitaMap from '@ambita/ambita-map';
import Proj4js from 'proj4';

export const UTM32 = '+proj=utm +zone=32';
export const UTM33 = '+proj=utm +zone=33';
export const WGS84 = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs';
const UTM33New =
  '+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs';

export default class GisUtil {
  static utmPointToLatLng(utmPLatLng: LatLng): AmbitaMap.LatLngExpression {
    const utmPointArrary = [utmPLatLng.lat, utmPLatLng.lng];

    const wgsPoint: number[] = Proj4js(UTM33, WGS84, utmPointArrary);

    const [lng, lat] = wgsPoint;

    return {
      lat,
      lng,
    };
  }

  static arcgisXYtoLatLng = (x: number, y: number) => {
    return Proj4js(UTM33New, WGS84, [x, y]);
  };

  static arcgisLatLngToXy = (
    lat: number,
    lng: number
  ): [x: number, y: number] => {
    return Proj4js(WGS84, UTM33New, [lat, lng]);
  };

  static latLngToUtmPoint(
    wsgLatLng: AmbitaMap.LatLngLiteral,
    datum: string = UTM32
  ): number[] {
    const wsgPointArrary = [wsgLatLng.lng, wsgLatLng.lat];
    return Proj4js(WGS84, datum, wsgPointArrary);
  }

  static utmBboxToLatLngBounds(utmBbox: number[]): LatLngBounds {
    const [latA, lngA, latB, lngB] = utmBbox;

    const utmPointA = {
      lat: latA,
      lng: lngA,
    };

    const utmPointB = {
      lat: latB,
      lng: lngB,
    };

    const wgsPointA = GisUtil.utmPointToLatLng(utmPointA as LatLng);
    const wgsPointB = GisUtil.utmPointToLatLng(utmPointB as LatLng);

    return AmbitaMap.latLngBounds(wgsPointA, wgsPointB);
  }

  static getCenter(lat1: number, lat2: number, lng1: number, lng2: number) {
    const latMin = Math.min(lat1, lat2);
    const latMax = Math.max(lat1, lat2);
    const lngMin = Math.min(lng1, lng2);
    const lngMax = Math.max(lng1, lng2);

    const x = Math.round(latMin + (latMax - latMin) / 2);
    const y = Math.round(lngMin + (lngMax - lngMin) / 2);
    return { x, y };
  }

  static getCenterLatLng(
    lat1: number,
    lat2: number,
    lng1: number,
    lng2: number
  ) {
    const latMin = Math.min(lat1, lat2);
    const latMax = Math.max(lat1, lat2);
    const lngMin = Math.min(lng1, lng2);
    const lngMax = Math.max(lng1, lng2);

    return {
      lat: (latMin + latMax) / 2,
      lng: (lngMin + lngMax) / 2,
    };
  }
}
