import { coreStore } from '@ambita/ambita-components-core';

export const GetAuthToken = (): Promise<string> => {
  return new Promise((resolve, reject) => {
    const tokenOrPromise = coreStore.getAccessToken();

    if (typeof tokenOrPromise === 'string') {
      resolve(tokenOrPromise);
    } else {
      tokenOrPromise.then(resolve).catch(reject);
    }
  });
};

export default { GetAuthToken };
