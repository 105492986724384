import { defineStore } from 'pinia';
import { ref, computed, nextTick } from 'vue';
import Realty, { PropertyData } from '@/data/Realty';
import { WKID } from '@/types/spatialReference';
import Point from '@arcgis/core/geometry/Point';
import Polygon from '@arcgis/core/geometry/Polygon';
import {
  SaveCadastre,
  DeleteCadastre,
  GetProject,
  GetCadastres,
} from '@/data/Projects';
import { Project } from '@/types/project';
import GisUtil from '@/utilities/GisUtil';
import { RealtyCadastreItem } from '@/data/RealtyTypes';
import logger from '@/utilities/Logger';

type PointXY = {
  x: number;
  y: number;
};

type PointLatLng = {
  lat: number;
  lng: number;
};

type PropertySelection = { [key: string]: PropertyData };

const getAllPolygons = (selection: PropertySelection) => {
  return Object.entries(selection)
    .filter((key) => key[1] && key[1].polygon)
    .map((key) => {
      return key[1].polygon;
    });
};

const getProperty = (lat: number, lng: number): Promise<PropertyData> => {
  return Realty.getPropertyByPosition({ lat, lng });
};

export const useProjectStore = defineStore('project', () => {
  const loadedProperties = ref<PropertySelection>({});
  type CadastreIndexKey = keyof typeof loadedProperties.value;

  const selectedCadastres = ref<CadastreIndexKey[]>([]);
  const currentProperty = ref<string | null>(null);
  const hoverProperty = ref<string | null>(null);
  const pointer = ref<PointLatLng | null>(null);
  const projectLoaded = ref<boolean>(false);
  const project = ref<Project | null>(null);

  const selectedProperties = computed(() => {
    const selection: PropertySelection = {};

    selectedCadastres.value.forEach((cadastre) => {
      selection[cadastre] = loadedProperties.value[cadastre];
    });

    return selection;
  });
  const projectId = computed(() => {
    return project.value?.Project || null;
  });

  const getCadastres = computed(() => Object.keys(selectedProperties));

  const getSelectedCadastresPolygons = computed(() =>
    getAllPolygons(selectedProperties.value)
  );

  const getCurrentProperty = computed(() => {
    if (currentProperty.value) {
      return loadedProperties.value[currentProperty.value];
    }
    return null;
  });

  const getCadastreCenterPointLatLng = (cadastre: RealtyCadastreItem) => {
    const [lat, lng] = cadastre.position.geometry.coordinates;

    return {
      lat,
      lng,
    };
  };

  const getSelectedCadastresCenterPointsLatLng = computed((): PointLatLng[] => {
    return Object.entries(selectedProperties.value).map((key) => {
      return getCadastreCenterPointLatLng(key[1].cadastre);
    });
  });

  const getSelectedCadastresCenterPointsXY = computed((): PointXY[] => {
    return getSelectedCadastresCenterPointsLatLng.value.map(({ lat, lng }) => {
      const [x, y] = GisUtil.arcgisLatLngToXy(lat, lng);

      return {
        x,
        y,
      };
    });
  });

  const getCurrentCadastresCenterPointsXY = computed((): PointXY | null => {
    const property = getCurrentProperty.value;
    if (!property) {
      return null;
    }
    const { lat, lng } = getCadastreCenterPointLatLng(property.cadastre);
    const [x, y] = GisUtil.arcgisLatLngToXy(lat, lng);

    return {
      x,
      y,
    };
  });

  const getCurrentCadastresCenterPointsLatLng = computed(
    (): PointLatLng | null => {
      const property = getCurrentProperty.value;
      if (!property) {
        return null;
      }

      return getCadastreCenterPointLatLng(property.cadastre);
    }
  );

  const getCurrentPropertyPolygon = computed(() => {
    if (!currentProperty.value) {
      return null;
    }

    return loadedProperties.value[currentProperty.value].polygon;
  });

  const getHoverPropertyPolygon = computed(() => {
    if (!hoverProperty.value) {
      return null;
    }

    return loadedProperties.value[hoverProperty.value].polygon;
  });

  const getTotalSelectedArea = computed(() => {
    return Object.entries(selectedProperties.value)
      .map(([_, cadastre]) => cadastre.cadastre.specifiedArea)
      .reduce((total, value) => total + value, 0);
  });

  const getProjectRoute = computed(() => {
    return `/prosjekter/${projectId.value}/kart`;
  });

  const movePointer = (lat: number, lng: number) => {
    if (lat === 0 || lng === 0) {
      pointer.value = null;
    } else {
      pointer.value = {
        lat,
        lng,
      };
    }
  };

  const removePointer = () => {
    pointer.value = null;
  };

  const setCurrentProperty = (cadastreKey: string | null) => {
    currentProperty.value = cadastreKey;
  };

  const setHoverProperty = (cadastreKey: string | null) => {
    hoverProperty.value = cadastreKey;
  };

  const addProperty = (
    cadastreKey: string,
    property: PropertyData,
    saveToProject: boolean
  ) => {
    if (saveToProject) {
      if (projectId.value === null) {
        return;
      }

      SaveCadastre(projectId.value, cadastreKey)
        .then(() => {
          selectedCadastres.value.push(cadastreKey);
          loadedProperties.value[cadastreKey] = property;
        })
        .catch(() => {
          logger.warn('Klarte ikke lagre matrikkel til prosjektet.');
        });
    } else {
      selectedCadastres.value.push(cadastreKey);
      loadedProperties.value[cadastreKey] = property;
    }
  };

  const viewProperty = (cadastreKey: string, property: PropertyData) => {
    loadedProperties.value[cadastreKey] = property;
    currentProperty.value = cadastreKey;
  };

  const removeProperty = (cadastreKey: string) => {
    if (projectId.value !== null) {
      DeleteCadastre(projectId.value, cadastreKey)
        .then(() => {
          selectedCadastres.value = selectedCadastres.value.filter(
            (cadastre) => cadastre !== cadastreKey
          );
        })
        .catch(() => {
          logger.warn('Klarte ikke slettte matrikkel fra prosjektet.');
        });
    }
  };

  const removeAllProperties = (saveToProject = false) => {
    if (saveToProject) {
      selectedCadastres.value.forEach((cadastre) => {
        if (typeof cadastre === 'string') {
          removeProperty(cadastre);
        }
      });
    }
    selectedCadastres.value = [];
  };

  const selectPropertyAtPosition = async (
    lat: number,
    lng: number
  ): Promise<PropertyData> => {
    const point = new Point({
      latitude: lat,
      longitude: lng,
      spatialReference: {
        wkid: WKID.WGS84,
      },
    });

    const matchedCadastres = Object.entries(loadedProperties.value).filter(
      ([_, cadastre]) => {
        const isSectioned = cadastre.cadastre.key.split('-').pop() !== '0';
        if (
          cadastre.polygon &&
          cadastre.polygon.type &&
          cadastre.polygon.coordinates &&
          !isSectioned
        ) {
          // ignore sections, and always select the base cadastre.
          const matchedPolygons = cadastre.polygon.coordinates.filter(
            (plot: any) => {
              const bounds = new Polygon({
                rings: plot,
                spatialReference: {
                  wkid: WKID.WGS84,
                },
              });

              return bounds.contains(point);
            }
          );
          return matchedPolygons.length > 0;
        }
        return false;
      }
    );
    const isAlreadySelected = matchedCadastres.length > 0;

    if (isAlreadySelected) {
      const property = matchedCadastres[0][1];
      viewProperty(property.cadastre.key, property);

      return Promise.resolve(matchedCadastres[0][1]);
    }
    return getProperty(lat, lng).then((property) => {
      const isOwnerless = property.cadastre.key.endsWith('0-0-0-0');

      if (!isOwnerless) {
        viewProperty(property.cadastre.key, property);
      }
      return property;
    });
  };

  const resetStore = () => {
    removeAllProperties();
    projectLoaded.value = false;
    currentProperty.value = null;
    hoverProperty.value = null;
    pointer.value = null;
    project.value = null;
  };

  const loadProject = async (id: string) => {
    resetStore();
    projectLoaded.value = false;

    GetProject(id).then((data) => {
      project.value = data as unknown as Project;

      if (project.value) {
        GetCadastres(project.value.Project).then((cadastres) => {
          Realty.getProperties(Object.keys(cadastres)).then((cadastreList) => {
            cadastreList.forEach((cadastre) => {
              addProperty(cadastre.cadastre.key, cadastre, false);
            });
            nextTick(() => {
              projectLoaded.value = true;
            });
          });
        });
      }
    });
  };

  return {
    // state
    project,
    projectId,
    loadedProperties,
    selectedProperties,
    selectedCadastres,
    currentProperty,
    hoverProperty,
    pointer,
    projectLoaded,

    // get
    getCadastres,
    getSelectedCadastresPolygons,
    getCurrentProperty,
    getHoverPropertyPolygon,
    getTotalSelectedArea,
    getCurrentPropertyPolygon,
    getProjectRoute,
    getCurrentCadastresCenterPointsXY,
    getCurrentCadastresCenterPointsLatLng,
    getSelectedCadastresCenterPointsXY,
    getSelectedCadastresCenterPointsLatLng,

    // actions
    viewProperty,
    setCurrentProperty,
    removeAllProperties,
    removeProperty,
    addProperty,
    setHoverProperty,
    selectPropertyAtPosition,
    removePointer,
    movePointer,
    loadProject,
  };
});
