import { defineStore } from 'pinia';
import GisUtil from '@/utilities/GisUtil';
import Extent from '@arcgis/core/geometry/Extent';
import { MapLayers } from '@/components/Map2D/MapLayers';
import logger from '@/utilities/Logger';

const MAPLAYERS_LOCALSTORAGE_KEY = 'pf-active-layers';
const BASELAYER_LOCALSTORAGE_KEY = 'pf-baselayer';
const LEGEND_EXPANSION_LOCALSTORAGE_KEY = 'pf-legend-open';

const getInitialActiveMapLayers = () => {
  const localStorageLayers = localStorage
    .getItem(MAPLAYERS_LOCALSTORAGE_KEY)
    ?.split('$PF$') || ['eiendomsGrenser'];
  const mapLayers = localStorageLayers.filter((layer) => layer !== '');

  return mapLayers;
};

const getInitialBaseLayers = (): string => {
  return localStorage.getItem(BASELAYER_LOCALSTORAGE_KEY) || 'monotone';
};

const getInitialLegendExpansion = (): boolean => {
  const state = localStorage.getItem(LEGEND_EXPANSION_LOCALSTORAGE_KEY);
  if (state === null) {
    return true;
  }

  const isExpanded = state === 'true';
  return isExpanded ?? true;
};

export const useMapStore = defineStore('map', {
  state: (): {
    baseLayer: string | number;
    activeView: '3D' | '2D';
    selectedLayers: MapLayers[];
    x: number;
    y: number;
    zoom: number;
    extent: Extent;
    legendOpen: boolean;
    screenshot: __esri.Screenshot | null;
    baseLayerOpacity: number;
  } => ({
    x: 262579.16443590284,
    y: 6648955.153945487,
    selectedLayers: getInitialActiveMapLayers(),
    baseLayer: getInitialBaseLayers(),
    activeView: '2D',
    zoom: 13,
    extent: {} as Extent,
    legendOpen: getInitialLegendExpansion(),
    screenshot: null,
    baseLayerOpacity: 1,
  }),
  getters: {
    getLatLng(): { lat: number; lng: number } {
      const [lng, lat] = GisUtil.arcgisXYtoLatLng(this.x, this.y);
      return { lat, lng };
    },
    getXY(): { x: number; y: number } {
      return {
        x: this.x,
        y: this.y,
      };
    },
  },
  actions: {
    setMapCenterXY(x: number, y: number) {
      this.x = x;
      this.y = y;
    },
    setMapCenterLatLng(lat: number, lng: number) {
      const [x, y] = GisUtil.arcgisLatLngToXy(lat, lng);
      this.x = x;
      this.y = y;
    },
    setMapZoom(zoom: number) {
      this.zoom = zoom;
    },
    zoomIn() {
      this.setMapZoom(this.zoom + 1);
    },
    zoomOut() {
      this.setMapZoom(this.zoom - 1);
    },
    setBaseLayer(baseLayer: string) {
      localStorage.setItem(BASELAYER_LOCALSTORAGE_KEY, baseLayer);
      this.baseLayer = baseLayer;
    },
    setBaseLayerOpacity(opacity: number) {
      this.baseLayerOpacity = opacity;
    },
    setActiveLayers(layers: MapLayers[]) {
      localStorage.setItem(MAPLAYERS_LOCALSTORAGE_KEY, layers.join('$PF$'));

      this.selectedLayers = layers;
    },
    setExtent(extent: Extent) {
      this.extent = {
        xmin: extent.xmin,
        xmax: extent.xmax,
        ymin: extent.ymin,
        ymax: extent.ymax,
      } as Extent;
    },
    async toggleActiveMapView() {
      const is3D = this.activeView === '3D';

      const toggleMapState = () => {
        this.activeView = !is3D ? '3D' : '2D';
      };

      setTimeout(() => {
        toggleMapState();
      }, 1000);
    },
    setLegendState(open: boolean) {
      localStorage.setItem(LEGEND_EXPANSION_LOCALSTORAGE_KEY, String(open));
      this.legendOpen = open;
    },
    captureScreenshot() {
      // Trigger listeners without doing anything else.
      logger.debug('Generating screenshot');
    },
    zoomToSelectedProperties() {
      // Trigger listeners without doing anything else.
      logger.debug('Zooming to selected properties');
    },
  },
});
