import logger from '@/utilities/Logger';

export interface PTMEvent {
  event_key?: string; // eslint-disable-line
  action?: string;
  value?: string;
  event?: string;
  url?: string;
  usertype?: string;
}

// eslint-disable-next-line
declare global { interface Window { dataLayer: any; } }

/* eslint-disable no-underscore-dangle */
const PTM = {
  routeChange(route: string, usertype?: string): void {
    const event: PTMEvent = {
      event: 'Pageview',
      event_key: route,
    };

    if (usertype) {
      event.usertype = usertype;
    }

    this._push_old(event);
  },
  event(category: string, action: string, name: string, value = 1): void {
    const event = {
      event: 'custom-event',
      category,
      action,
      name,
      value,
    };

    this._push(event);
  },
  event_old(eventName: string, data: PTMEvent = {}): void {
    const event: PTMEvent = {
      event: eventName,
      event_key: data.event_key ? data.event_key : '',
      action: data.action ? data.action : '',
      value: data.value ? data.value : '',
    };

    if (data.url) {
      event.url = data.url;
    }

    if (data.usertype) {
      event.usertype = data.usertype;
    }

    this._push_old(event);
  },

  _push_old(content: PTMEvent): void {
    if (Object.prototype.hasOwnProperty.call(window, 'dataLayer')) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(content);
    } else if (process.env.NODE_ENV === 'production') {
      logger.error('No PTM instance loaded.');
    }
  },
  _push(content: { [key: string]: unknown }): void {
    if (Object.prototype.hasOwnProperty.call(window, 'dataLayer')) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(content);
    } else if (process.env.NODE_ENV === 'production') {
      logger.error('No PTM instance loaded.');
    }
  },
};
/* eslint-enable no-underscore-dangle */

export default PTM;
