export function HasProperty(
  input: unknown,
  property: string | number
): boolean {
  let result = input !== undefined && input !== null;
  result &&= Object.prototype.hasOwnProperty.call(input, property);

  if (result) {
    const obj = input as Record<string, unknown>;
    result = obj[property] !== undefined && obj[property] !== null;
  }

  return result;
}

export function ValidateString(input: string, minLength = 1): boolean {
  if (typeof input !== 'string' || input.length < minLength) {
    return false;
  }

  return true;
}

export function ValidateNumber(input: number): boolean {
  if (typeof input !== 'number') {
    return false;
  }

  return !Number.isNaN(input);
}

export function ValidateCadastre(cadastre: string): boolean {
  const regex = /^[0-9]{3,4}(-[0-9]{1,4}){3,4}$/;
  return ValidateString(cadastre, 9) && regex.test(cadastre);
}

export function ValidateProjectId(id: string): boolean {
  const regex = /^[a-f0-9-]{36}$/;
  return ValidateString(id, 36) && regex.test(id);
}

export function ValidateProjectName(name: string): boolean {
  const regex = new RegExp(
    '^[a-zA-Z0-9æøåÆØÅäöÄÖüÜéèÉÈáàóòÓÒúÙÁÀõÕôÔêÊçÇíìÍÌñÑß!?¿¡\\ #\\-––—_$€' +
    '£¥₽§@^,\\.]{3,150}$' // eslint-disable-line prettier/prettier
  );

  return ValidateString(name, 3) && regex.test(name);
}

export default {
  HasProperty,
  ValidateString,
  ValidateCadastre,
  ValidateProjectId,
  ValidateProjectName,
};
