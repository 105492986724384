import { XhrRequest, XhrRequestInit } from './XhrRequest';

export class JSONRequest extends XhrRequest {
  deleteJSON(
    url: string | URL,
    body: Record<string, string>,
    headers?: Record<string, string>
  ): Promise<Record<string, unknown>> {
    const hdrs = headers || {};

    hdrs.Accept = 'application/json';
    hdrs['Content-Type'] = 'application/json';

    return super.delete(url, JSON.stringify(body), hdrs).then((r) => r.json());
  }

  getJSON<T>(url: string | URL, headers?: Record<string, string>): Promise<T> {
    const hdrs = headers || {};
    hdrs.Accept = 'application/json';

    return super.get(url, hdrs).then((r) => r.json<T>());
  }

  patchJSON(
    url: string | URL,
    body: Record<string, string>,
    headers?: Record<string, string>
  ): Promise<Record<string, unknown>> {
    const hdrs = headers || {};

    hdrs.Accept = 'application/json';
    hdrs['Content-Type'] = 'application/json';

    return super.patch(url, JSON.stringify(body), hdrs).then((r) => r.json());
  }

  postJSON(
    url: string | URL,
    body: Record<string, unknown>,
    headers?: Record<string, string>
  ): Promise<Record<string, unknown>> {
    const hdrs = headers || {};

    hdrs.Accept = 'application/json';
    hdrs['Content-Type'] = 'application/json';

    return super.post(url, JSON.stringify(body), hdrs).then((r) => r.json());
  }

  putJSON(
    url: string | URL,
    body: Record<string, unknown>,
    headers?: Record<string, string>
  ): Promise<Record<string, unknown>> {
    const hdrs = headers || {};

    hdrs.Accept = 'application/json';
    hdrs['Content-Type'] = 'application/json';

    return super.put(url, JSON.stringify(body), hdrs).then((r) => r.json());
  }

  requestJSON(
    url: string | URL,
    init: XhrRequestInit
  ): Promise<Record<string, unknown>> {
    const modifiedInit = init;
    const hdrs = init.headers ? init.headers : {};

    hdrs.Accept = 'application/json';
    hdrs['Content-Type'] = 'application/json';

    modifiedInit.headers = hdrs;

    return super.request(url, modifiedInit).then((r) => r.json());
  }
}

export default { JSONRequest };
