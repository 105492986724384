import { JSONRequest } from './JSONRequest';
import { XhrRequestInit } from './XhrRequest';
import { XhrResponse } from './XhrResponse';

export class BearerRequest extends JSONRequest {
  #tokenGenerator: () => Promise<string>;

  constructor(tokenGenerator: () => Promise<string>) {
    super();
    this.#tokenGenerator = tokenGenerator;
  }

  request(url: string | URL, init: XhrRequestInit): Promise<XhrResponse> {
    return this.#tokenGenerator().then((token: string) => {
      const initCopy = init;

      initCopy.headers = init.headers ? init.headers : {};
      initCopy.headers.Authorization = String(token);

      return super.request(url, initCopy);
    });
  }
}

export default { BearerRequest };
