import ST from 'geojson-bounds';
import Leaflet from 'leaflet';
import PolygonLookup from 'polygon-lookup';
import AmbitaMap from '@ambita/ambita-map';
import { Geometry, GeoJsonProperties } from 'geojson';

/* eslint-disable import/no-cycle */
// @TODO: fix dependency cycle
import PropFinderMap from '@/components/Map/PropfinderMap';
/* eslint-enable import/no-cycle */
import { RealtyPolygon } from '../RealtyTypes';

export default class Polygon implements RealtyPolygon {
  public type: string;

  public coordinates: number[][][][];

  constructor(realtyItem: RealtyPolygon) {
    this.type = realtyItem.type;
    this.coordinates = realtyItem.coordinates;
  }

  /**
   * Returns the bounds (in Leaflet format) for this polygon
   * @returns {null|LatLngBounds|null|N}
   */
  public bounds(): Leaflet.LatLngBounds | null {
    const extent = ST.extent(this as RealtyPolygon);

    if (!extent) {
      return null;
    }

    const [first, second, third, fourth] = extent;
    const corner1 = AmbitaMap.latLng(second, first);
    const corner2 = AmbitaMap.latLng(fourth, third);

    return AmbitaMap.latLngBounds(corner1, corner2);
  }

  /**
   * Checks if this polygon is within the given leaflet-map
   * @param propFinderMap
   * @returns {boolean|*}
   */
  public isWithinMap(propFinderMap: PropFinderMap): boolean {
    const bounds = this.bounds();

    if (bounds) {
      return propFinderMap.bounds().contains(bounds);
    }

    return false;
  }

  public isNotWithinMap(propFinderMap: PropFinderMap): boolean {
    return !this.isWithinMap(propFinderMap);
  }

  /**
   * Checks if this polygon contains the given point (latLng)
   * @param latLng
   * @returns {boolean}
   */
  public contains(latLng: Leaflet.LatLng): boolean {
    const featureCollection = {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: { id: 'test' },
          geometry: this as RealtyPolygon,
        },
      ],
    };

    const lookup = new PolygonLookup(
      featureCollection as unknown as GeoJSONFeatureCollection<
        Geometry,
        GeoJsonProperties
      >
    );
    const polygon = lookup.search(latLng.lng, latLng.lat);

    return polygon !== undefined;
  }
}
