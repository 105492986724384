export class XhrResponse {
  #xhr: XMLHttpRequest;

  constructor(xhr: XMLHttpRequest) {
    this.#xhr = xhr;
  }

  json<T>(): Promise<T> {
    try {
      const json = JSON.parse(this.#xhr.responseText);
      return Promise.resolve(json);
    } catch (e: unknown) {
      if (e instanceof Error) {
        return Promise.reject(e);
      }
      return Promise.reject(new Error(String(e)));
    }
  }

  xml(): Promise<Document> {
    try {
      const xml = this.#xhr.responseXML;

      if (xml) {
        return Promise.resolve(xml);
      }

      throw new Error('response is null');
    } catch (e) {
      if (e instanceof Error) {
        return Promise.reject(e);
      }
      return Promise.reject(new Error(String(e)));
    }
  }

  response(): Promise<unknown> {
    return Promise.resolve(this.#xhr.response);
  }
}

export default { XhrResponse };
