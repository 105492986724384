/* eslint-disable max-classes-per-file */

export class LoadingEvent extends Event {
  public details?: string;
}

export class ToolbarButtonEvent extends Event {
  public name?: string;

  public active?: boolean;

  constructor(type: string, name: string, active: boolean) {
    super(type);
    this.name = name;
    this.active = active;
  }
}

export class ProjectCreateEvent extends Event {
  readonly onCreated: (id: string) => void;

  readonly onCancel?: () => void;

  constructor(createdCB: (id: string) => void, cancelCB?: () => void) {
    super('ProjectCreateEvent');
    this.onCreated = createdCB;
    this.onCancel = cancelCB;
  }
}

export interface AuxCheckboxEvent {
  checked: boolean;
  option: string;
}
