import { LoggerLevel } from '@/types/loggerLevels';

const isProduction = false;
const loggerLevel = 1;

type LoggerCall = unknown[];

const logger = {
  error: (...args: LoggerCall) => {
    if (isProduction && loggerLevel <= LoggerLevel.error) {
      // log Cloudwatch
    } else {
      // eslint-disable-next-line no-console
      console.error(...args);
    }
  },
  info: (...args: LoggerCall) => {
    if (isProduction && loggerLevel <= LoggerLevel.info) {
      // log Cloudwatch
    } else {
      // eslint-disable-next-line no-console
      console.info(...args);
    }
  },
  warn: (...args: LoggerCall) => {
    if (isProduction && loggerLevel <= LoggerLevel.warn) {
      // log Cloudwatch
    } else {
      // eslint-disable-next-line no-console
      console.warn(...args);
    }
  },
  debug: (...args: LoggerCall) => {
    if (isProduction && loggerLevel <= LoggerLevel.debug) {
      // log Cloudwatch
    } else {
      // eslint-disable-next-line no-console
      console.debug(...args);
    }
  },
  log: (...args: LoggerCall) => {
    if (isProduction && loggerLevel <= LoggerLevel.log) {
      // log Cloudwatch
    } else {
      // eslint-disable-next-line no-console
      console.log(...args);
    }
  },
};

export default logger;
