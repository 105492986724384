import { BearerRequest } from '@/data/xhr/BearerRequest';
import { GetAuthToken } from '@/data/xhr/AuthToken';
import IdentityManager from '@arcgis/core/identity/IdentityManager';
import logger from '@/utilities/Logger';

const API = String(process.env.VUE_APP_BACKEND_ENDPOINT);
const ARCGIS_SERVER = String(process.env.VUE_APP_ARCGIS_ENDPOINT);

let tokenRegistered = false;

export async function getGeodataToken() {
  const request = new BearerRequest(GetAuthToken);

  let response: Record<string, string>;
  try {
    response = await request.getJSON<Record<string, string>>(
      `${API}/token/geodata`
    );
  } catch (error) {
    if (error instanceof Error) {
      logger.error(`Geodata token error: ${error.message}`);
    }
    throw new Error(`Unable to get Geodata token: ${error}`);
  }

  if (!Object.prototype.hasOwnProperty.call(response, 'token')) {
    throw new Error(`Unable to get Geodata token: ${response.message}`);
  }

  return response.token;
}

export async function registerGeodataToken() {
  if (tokenRegistered) {
    return;
  }

  try {
    const token = await getGeodataToken();

    IdentityManager.registerToken({
      server: ARCGIS_SERVER,
      token,
    });

    tokenRegistered = true;
  } catch (error) {
    throw new Error(`Unable to register Geodata token: ${error}`);
  }
}
