import axios, { AxiosError, AxiosResponse } from 'axios';
import { coreStore } from '@ambita/ambita-components-core';
import { LatLng, LatLngLiteral } from 'leaflet';
import { LoadingEvent } from '@/utilities/CustomEvents';
import logger from '@/utilities/Logger';

/* eslint-disable import/no-cycle */
// @TODO: fix dependency cycle
import Polygon from '@/data/wrapper/Polygon';
/* eslint-enable import/no-cycle */
import * as RealtyTypes from './RealtyTypes';

export interface RelatedToCadastre {
  polygon: Polygon | null;
  ownerships: RealtyTypes.RealtyOwnerShip[] | null;
  address: RealtyTypes.RealtyPresentationAddress | null;
  buildings: RealtyTypes.RealtyBuildings[] | null;
  latestConveyance: RealtyTypes.RealtyConveyances | null;
  heritages: RealtyTypes.RealtySingleHeritage[] | null;
  soilContaminations: RealtyTypes.RealtySoilContamination[] | null;
}

export interface PropertyData extends RelatedToCadastre {
  cadastre: RealtyTypes.RealtyCadastreItem;
}

export type LandRegistryPart =
  | 'EASEMENTS'
  | 'LANDDATA'
  | 'PRIVILEGES'
  | 'MONETARY_ENCUMBRANCES'
  | 'TITLES'
  | 'ENCUMBRANCES';

export type LandRegistryState =
  | 'active'
  | 'historical'
  | 'active_and_historical';

// eslint-disable-next-line
declare global { interface Window { L: any; } }
type CacheItem = { timeStamp: number; data: any };
let cache: { [key: string]: CacheItem } = {};
const FIVE_MINUTES = 60 * 1000 * 5;
const SIX_HOURS = 6 * 60 * 60 * 1000;
let cacheSize = 0;

const cleanCache = () => {
  if (cacheSize >= 3000) {
    cache = {};
    cacheSize = 0;
  }
};

const checkCacheExpiry = (item: CacheItem) => {
  const now = Date.now();
  const cacheAge = now - item.timeStamp;
  const isExpired = cacheAge > SIX_HOURS;

  return isExpired;
};

const expireCache = () => {
  Object.entries(cache).forEach(([key, item]) => {
    if (checkCacheExpiry(item)) {
      delete cache[key];
    }
  });
};

setInterval(expireCache, FIVE_MINUTES);

const cacheAdd = (key: string, element: any) => {
  cleanCache();
  cache[key] = { timeStamp: Date.now(), data: element };
  cacheSize += 1;
};

const cacheGet = (url: string) => {
  let isExpired = false;
  if (cache[url]) {
    isExpired = checkCacheExpiry(cache[url]);
  }

  if (isExpired) {
    return null;
  }

  return cache[url];
};

export default class Realty {
  static baseUrl = `${process.env.VUE_APP_AMBITA_API}/realty/`;

  static async getPropertyByPosition(
    pos: LatLngLiteral
  ): Promise<PropertyData> {
    const cadastre = await this.getFirstCadastreByPosition(pos);
    const data = await this.getProperty(cadastre.key);
    return data;
  }

  static async getPolygonByPosition(pos: LatLngLiteral): Promise<{
    polygon: Polygon | null;
    cadastre: RealtyTypes.RealtyCadastreItem;
  }> {
    const cadastre = await this.getFirstCadastreByPosition(pos);
    const polygon = await this.getPolygon(cadastre.key);
    return { cadastre, polygon };
  }

  static getFirstCadastreByPosition(
    pos: LatLngLiteral
  ): Promise<RealtyTypes.RealtyCadastreItem> {
    return new Promise<RealtyTypes.RealtyCadastreItem>((resolve, reject) => {
      this.getCadastresByPosition(pos)
        .then((result: RealtyTypes.RealtyCadastreResponse) => {
          if (
            !result ||
            !Object.prototype.hasOwnProperty.call(result, 'items') ||
            !result.items ||
            result.items.length < 1
          ) {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject('Ingen eiendom funnet');
          } else {
            resolve(this.selectFirstPreferMainCadastres(result.items));
          }
        })
        .catch((reason) => {
          logger.error(reason);
          // eslint-disable-next-line prefer-promise-reject-errors
          reject(`En feil har oppstått ved oppslag av eiendom. ${reason}`);
        });
    });
  }

  static selectFirstPreferMainCadastres(
    cadastres: RealtyTypes.RealtyCadastreItem[]
  ): RealtyTypes.RealtyCadastreItem {
    if (cadastres.length === 1) {
      return cadastres[0];
    }

    const mainCadastres = cadastres
      .filter(
        (c: RealtyTypes.RealtyCadastreItem) => c.ident.sectionNumber === 0
      )
      .sort((a, b) => a.specifiedArea - b.specifiedArea);

    if (mainCadastres.length > 0) {
      logger.info('Flere seksjoner funnet. Returnerer hovedeiendom (snr=0)');
      return mainCadastres[0];
    }

    return cadastres[0];
  }

  static getCadastresByPosition(
    pos: LatLngLiteral
  ): Promise<RealtyTypes.RealtyCadastreResponse> {
    return this.getData(
      `v1/positions/${pos.lng},${pos.lat},wgs84/cadastres/?pagesize=200&datum=WGS84`
    ) as Promise<unknown> as Promise<RealtyTypes.RealtyCadastreResponse>;
  }

  static getPlotPolygonByPosition(pos: LatLng): Promise<Polygon> {
    return this.getData(
      `v1/positions/${pos.lng},${pos.lat},wgs84/plot/polygon/`
    ) as Promise<unknown> as Promise<Polygon>;
  }

  /**
   * Retrieves a list of properties for the given list of cadastre keys
   * @param cadastreKeys - List of keys
   * @returns {Promise<[Property]>} - Promise with a list of properties
   */
  static getProperties(cadastreKeys: string[]): Promise<PropertyData[]> {
    const promises = cadastreKeys.map((cadastreKey) =>
      Realty.getProperty(cadastreKey)
    );
    return Promise.all(promises);
  }

  /**
   * Retrieved a complete Property with related data
   * @param cadastreKey
   * @returns {Promise<Property | never>} - Promise with a property object
   */
  static getProperty(cadastreKey: string): Promise<PropertyData> {
    return new Promise<PropertyData>((resolve, reject) => {
      let cadastre: RealtyTypes.RealtyCadastreItem;

      Realty.getCadastreByKey(cadastreKey)
        .then((result) => {
          if (!result.item) {
            throw new Error(`Tomt datasett for matrikkel ${cadastreKey}`);
          }

          /* Key may differ from original cadastre key */
          cadastre = result.item;
          return Realty.getRelatedToCadastre(cadastre);
        })
        .then((related) => {
          resolve({
            cadastre,
            ...related,
          });
        })
        .catch(reject);
    });
  }

  static getRelatedToCadastre(
    cadastre: RealtyTypes.RealtyCadastreItem
  ): Promise<RelatedToCadastre> {
    return new Promise<RelatedToCadastre>((resolve, reject) => {
      if (this.cadastreIsVannteigOrEierlos(cadastre.key)) {
        resolve({
          polygon: null,
          ownerships: null,
          address: null,
          buildings: null,
          latestConveyance: null,
          heritages: null,
          soilContaminations: null,
        });
        return;
      }

      Promise.allSettled([
        Realty.getPolygon(cadastre.key),
        Realty.getOwnerships(cadastre.key),
        Realty.getPresentationAddress(cadastre.key),
        Realty.getBuildings(cadastre.key),
        Realty.getLatestConveyance(cadastre.key),
        Realty.getSingleHeritages(cadastre.key),
        Realty.getSoilContaminations(cadastre.key),
      ])
        .then(
          ([
            polygonResponse,
            ownershipResponse,
            presentationAddressResponse,
            buildingsResponse,
            conveyanceResponse,
            singleHeritagesResponse,
            soilContaminationResponse,
          ]) => {
            const response: RelatedToCadastre = {
              polygon: null,
              ownerships: null,
              address: null,
              buildings: null,
              latestConveyance: null,
              heritages: null,
              soilContaminations: null,
            };

            if (polygonResponse.status === 'fulfilled') {
              response.polygon = polygonResponse.value;
            } else {
              reject(new Error('Failed to fetch polygon'));
            }

            if (ownershipResponse.status === 'fulfilled') {
              response.ownerships = ownershipResponse.value.items;
            } else {
              reject(new Error('Failed to fetch owners'));
            }

            if (presentationAddressResponse.status === 'fulfilled') {
              response.address = presentationAddressResponse.value.item;
            } else {
              reject(new Error('Failed to fetch address'));
            }

            if (buildingsResponse.status === 'fulfilled') {
              response.buildings = buildingsResponse.value.items;
            } else {
              reject(new Error('Failed to fetch address'));
            }

            if (conveyanceResponse.status === 'fulfilled') {
              response.latestConveyance = conveyanceResponse.value;
            } else {
              reject(new Error('Failed to fetch address'));
            }

            if (singleHeritagesResponse.status === 'fulfilled') {
              response.heritages = singleHeritagesResponse.value.items;
            } else {
              reject(new Error('Failed to fetch single herritages'));
            }

            if (soilContaminationResponse.status === 'fulfilled') {
              response.soilContaminations =
                soilContaminationResponse.value.items;
            } else {
              reject(new Error('Failed to fetch soil contaminations'));
            }

            resolve(response);
          }
        )
        .catch((reason) => reject(reason));
    });
  }

  static cadastreIsVannteigOrEierlos(cadastreKey: string): boolean {
    const key = cadastreKey.trim();

    return key.endsWith('-0-0-0-0') || key.endsWith('-0-1-0-0');
  }

  static getCadastresByAddressKey(
    addressKey: string
  ): Promise<RealtyTypes.RealtyCadastreResponse> {
    return this.getData(
      `v1/addresses/${addressKey}/cadastres/?datum=WGS84`
    ) as Promise<unknown> as Promise<RealtyTypes.RealtyCadastreResponse>;
  }

  static getCadastreByKey(
    cadastreKey: string
  ): Promise<RealtyTypes.RealtySingleCadastreResponse> {
    return this.getData(
      `v1/cadastres/${cadastreKey}?datum=WGS84`
    ) as Promise<unknown> as Promise<RealtyTypes.RealtySingleCadastreResponse>;
  }

  static getOwners(
    cadastreKey: string
  ): Promise<RealtyTypes.RealtyOwnerResponse> {
    return this.getData(
      `v1/cadastres/${cadastreKey}/owners/?&onlyMaxCadastreLevel=true&pagesize=200`
    ) as Promise<unknown> as Promise<RealtyTypes.RealtyOwnerResponse>;
  }

  static getEstimatedValue(cadastreKey: string): Promise<any> {
    return this.getData(
      `v1/cadastres/${cadastreKey}/housingvalue/`
    ) as Promise<unknown> as Promise<any>;
  }

  static getOwnerships(
    cadastreKey: string
  ): Promise<RealtyTypes.RealtyOwnerShipResponse> {
    return this.getData(
      `v1/cadastres/${cadastreKey}/ownershipsowningthiscadastre/?&onlyMaxCadastreLevel=true&pagesize=200`
    ) as Promise<unknown> as Promise<RealtyTypes.RealtyOwnerShipResponse>;
  }

  static getOwnedOwnerships(cadastreKey: string) {
    return this.getData(
      `v1/cadastres/${cadastreKey}/ownershipsownedbythiscadastre/?&onlyMaxCadastreLevel=true&pagesize=200`
    ) as Promise<unknown> as Promise<RealtyTypes.RealtyCadastreOwnedOwnerships>;
  }

  static getSections(cadastreKey: string) {
    return this.getData(
      `v1/cadastres/${cadastreKey}/sections/?&pagesize=9999`
    ) as Promise<unknown> as Promise<RealtyTypes.RealtySectionsResponse>;
  }

  static getPolygon(cadastreKey: string): Promise<Polygon | null> {
    return new Promise((resolve, reject) => {
      this.getData(`v1/cadastres/${cadastreKey}/polygon/`)
        .then((data) => {
          if (data.type === undefined) {
            resolve(null);
            return;
          }
          resolve(new Polygon(data as unknown as RealtyTypes.RealtyPolygon));
        })
        .catch(reject);
    });
  }

  static getBuildings(cadastreKey: string) {
    return this.getData(
      `v1/cadastres/${cadastreKey}/buildings/?datum=wgs84&pagesize=200&includeSections=true`
    ) as Promise<unknown> as Promise<RealtyTypes.RealtyBuildingsResponse>;
  }

  static getBuilding(buildingKey: string) {
    return this.getData(`v1/buildings/${buildingKey}?datum=wgs84`).then(
      (result) => result.item
    ) as Promise<unknown> as Promise<RealtyTypes.RealtyBuildings>;
  }

  static getPlots(
    cadastreKey: string
  ): Promise<RealtyTypes.RealtyPlotsResponse> {
    return this.getData(
      `v1/cadastres/${cadastreKey}/plots/?datum=wgs84&pagesize=200`
    ) as Promise<unknown> as Promise<RealtyTypes.RealtyPlotsResponse>;
  }

  static getHousingValue(
    cadastreKey: string
  ): Promise<RealtyTypes.RealtyHousingValueResponse> {
    return this.getData(
      `v1/cadastres/${cadastreKey}/housingvalue/`
    ) as Promise<unknown> as Promise<RealtyTypes.RealtyHousingValueResponse>;
  }

  static getLatestConveyance(
    cadastreKey: string
  ): Promise<RealtyTypes.RealtyConveyances | null> {
    return new Promise<RealtyTypes.RealtyConveyances | null>(
      (resolve, reject) => {
        this.getData(
          `v1/cadastres/${cadastreKey}/conveyances/?pagesize=1&freeMarketOnly=true&sortByDateDescending=true`
        )
          .then(
            (response: Record<string, unknown>) =>
              response as unknown as RealtyTypes.RealtyConveyancesResponse
          )
          .then((response: RealtyTypes.RealtyConveyancesResponse) => {
            if (response.items.length === 0) {
              resolve(null);
            }

            resolve(response.items[0]);
          })
          .catch((error: AxiosError) => {
            reject(error);
          });
      }
    );
  }

  static getSingleHeritages(
    cadastreKey: string
  ): Promise<RealtyTypes.RealtySingleHeritagesResponse> {
    return this.getData(
      `v1/cadastres/${cadastreKey}/singleheritages/?pagesize=200`
    ) as Promise<unknown> as Promise<RealtyTypes.RealtySingleHeritagesResponse>;
  }

  static getSingleHeritage(
    singleHeritageKey: string
  ): Promise<RealtyTypes.RealtySingleHeritage> {
    return this.getData(`v1/singleheritages/${singleHeritageKey}`).then(
      (result) => result.item
    ) as Promise<unknown> as Promise<RealtyTypes.RealtySingleHeritage>;
  }

  static getSoilContaminations(
    cadastreKey: string
  ): Promise<RealtyTypes.RealtySoilContaminationResponse> {
    return this.getData(
      `v1/cadastres/${cadastreKey}/soilcontaminations/?pagesize=200`
    ) as Promise<unknown> as Promise<RealtyTypes.RealtySoilContaminationResponse>;
  }

  static getSoilContamination(
    soilContaminationKey: string
  ): Promise<RealtyTypes.RealtySoilContamination> {
    return this.getData(`v1/soilcontaminations/${soilContaminationKey}`).then(
      (result) => result.item
    ) as Promise<unknown> as Promise<RealtyTypes.RealtySoilContamination>;
  }

  static getPresentationAddress(
    cadastreKey: string
  ): Promise<RealtyTypes.RealtyPresentationAddressResponse> {
    return this.getData(
      `v1/cadastres/${cadastreKey}/presentationaddress/`
    ) as Promise<unknown> as Promise<RealtyTypes.RealtyPresentationAddressResponse>;
  }

  static getLandRegistry(
    cadastreKey: string,
    part: LandRegistryPart,
    state: LandRegistryState = 'active_and_historical'
  ): Promise<RealtyTypes.RealtyLandRegistry> {
    return this.getData(
      `v1/cadastres/${cadastreKey}/landregistry/?parts=${part}&state=${state}`
    ).then(
      (result) => result.item
    ) as Promise<unknown> as Promise<RealtyTypes.RealtyLandRegistry>;
  }

  static newPromise(data: unknown): Promise<unknown> {
    return new Promise((resolve) => {
      resolve(data);
    });
  }

  static nullResult(): Promise<null> {
    return new Promise((resolve) => {
      resolve(null);
    });
  }

  static itemsEmpty(): Record<string, unknown> {
    return {
      error: {
        returnCode: 0,
        message: '',
      },
      info: {
        realtyCode: 0,
        message: '',
        links: [],
      },
      item: null,
      items: [],
      pagination: {
        previous: '',
        next: '',
      },
    };
  }

  static getData(
    url: string,
    dispatchEvents = true
  ): Promise<Record<string, unknown>> {
    const loadingEvent = new LoadingEvent('loading');
    loadingEvent.details = url;

    if (dispatchEvents) {
      document.dispatchEvent(loadingEvent);
    }

    return new Promise<Record<string, unknown>>((resolve, reject) => {
      const cacheResult = cacheGet(url);

      if (cacheResult) {
        resolve(cache[url].data);

        if (dispatchEvents) {
          const loadingCompleteEvent = new LoadingEvent('loadingComplete');
          loadingCompleteEvent.details = url;
          document.dispatchEvent(loadingCompleteEvent);
        }
      } else {
        this.get(`${url}`)
          .then((result: AxiosResponse<Record<string, unknown>>) => result.data)
          .then((result: Record<string, unknown>) => {
            const loadingCompleteEvent = new LoadingEvent('loadingComplete');
            loadingCompleteEvent.details = url;

            if (dispatchEvents) {
              document.dispatchEvent(loadingCompleteEvent);
            }

            cacheAdd(url, result);
            resolve(result);
          })
          .catch((error: AxiosError) => {
            if (error.response && error.response.status === 404) {
              const loadingCompleteEvent = new LoadingEvent('loadingComplete');
              loadingCompleteEvent.details = url;

              if (dispatchEvents) {
                document.dispatchEvent(loadingCompleteEvent);
              }

              return resolve(this.itemsEmpty());
            }

            // eslint-disable-next-line prefer-promise-reject-errors
            return reject(`Feil oppstod ved kall til <${url}>: ${error}`);
          });
      }
    });
  }

  static get(url: string): Promise<AxiosResponse<Record<string, unknown>>> {
    const tokenOrPromise = coreStore.getAccessToken();

    const tokenPromise = new Promise<string>((resolve, reject) => {
      if (typeof tokenOrPromise === 'string') {
        resolve(tokenOrPromise);
      } else {
        tokenOrPromise.then(resolve).catch(reject);
      }
    });

    return tokenPromise.then((token) =>
      axios.get<Record<string, unknown>>(this.baseUrl + url, {
        headers: {
          Authorization: token as string,
        },
      })
    );
  }
}
